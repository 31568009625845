import React, { FC, useContext } from 'react';
import { Grid, Segment } from 'semantic-ui-react';

import UserContext from '@/components/UserContext';
import CsvDataExport from '@/components/CsvDataExport';
import CsvLogExport from '@/components/CsvLogExport';

const DataTab: FC = () => {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = 'VFA Dashboard (Export)';
    }
  }, []);

  const userContext = useContext(UserContext);
  const groups: string[] =
    userContext?.signInUserSession?.accessToken?.payload?.['cognito:groups'] ??
    [];
  return (
    <Grid stackable columns={3}>
      {groups.includes('Admins') && (
        <Grid.Column>
          <Segment>
            <CsvDataExport />
          </Segment>
        </Grid.Column>
      )}
      <Grid.Column>
        <Segment>
          <CsvLogExport />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default DataTab;
